import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { MobileMenu, DesktopMenu } from "./menu"
import teamLogo from "../img/worqpass.svg"
import "lazysizes"
const NavigationTeam = () => {
  const data = useStaticQuery(graphql`
    query TeamPageQuery {
      allCities {
        edges {
          node {
            city
            cityPriority
            displayCity
            slug
          }
        }
      }
      allListings {
        totalCount
        edges {
          node {
            monthPassAvailable
            dayPassAvailable
            hourPassAvailable
            officeSpaceType
            purposesList
            spaceAddress
            spaceGFName
            OriginalName
            spaceCity
            spaceId
            spaceImage
            spaceTitle
            spaceDesc
            spaceType
            subType
            priceDay
            priceHr
            priceMonth
            spaceDisplayName
            Facility
            slug
            hasCovidSafeBadge
            online
            Rating
          }
        }
      }
    }
  `)
  const [menuState, setmenuState] = useState("close")
  const MenuOpen = () => {
    if (menuState === "open") {
      setmenuState("close")
    } else {
      setmenuState("open")
    }
    //console.log(menuState)
  }
  const [scrollState, setscrollState] = useState("top")
  const handleScroll = () => {
    if (window.scrollY === 0) {
      setscrollState("top")
    } else if (window.scrollY !== 0) {
      setscrollState("onmoving")
    }
  }
  if (typeof window !== "undefined") {
    window.addEventListener("scroll", handleScroll)
  }

  return (
    <div>
      <header className="header TeamNav">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg navbar-light">
            <Link className="navbar-brand logo center-logo" to="/">
              <img src={teamLogo} alt="GoFloaters" />
            </Link>
            <button
              className="navbar-toggler"
              name="mobilenavigation"
              type="button"
              onClick={() => {
                MenuOpen("All")
              }}
            >
              <i className="fa fa-navicon"></i>
            </button>
            <DesktopMenu data={data} />
          </nav>
        </div>
      </header>
      <div className={"Nav mobile-only " + menuState}>
        <div className="text-right">
          <button
            className="navbar-toggler"
            name="MobileNavigation"
            type="button"
            onClick={() => {
              MenuOpen("All")
            }}
          >
            <i className="fa fa-close"></i>
          </button>
        </div>
        <MobileMenu data={data} />

        <div className="padding-20"></div>
        <br></br>
        <Link className="navbar-brand logo" to="/">
          <img
            data-src="https://assets.gofloaters.com/logo.png"
            className="lazyload"
            alt="GoFloaters"
          />
        </Link>
      </div>
    </div>
  )
}

export default NavigationTeam
